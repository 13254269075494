import { OrderTypes } from './../core/url-builder';
import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { OrdersService } from './orders.service';
import { environment } from 'environments/environment';
import { urls } from 'app/core/urls';
import { ErrorModalService } from 'app/core/modal/error-modal.service';
import { UrlBuilderService } from 'app/core/url-builder';
import { RequiredChildsData } from './../core/required-childs-data';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ProductPrintModalComponent } from './product-print-modal.component';
import { IOrder, IOrderItem, IOrderItemFull, IOrderViewModel } from './orders.models';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { LayoutFormatType, PrinterStatus, PrintingService, SearchProductModel } from 'app/printing/printing.service';
import { Observable, throwError } from 'rxjs';
import { ProductCopyModelRequest, ProductCopyModelResponse, ProductService } from 'app/products/products.service';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit, DoCheck {
  loading = false;
  orders: Array<IOrderViewModel> = [];
  salesOrders: Array<IOrderViewModel> = [];
  refundsOrders: Array<IOrderViewModel> = [];
  pages: Array<number> = [];
  selectedPage = 1;
  searchText = '';
  status = 'ready';
  salesStatus = 'regular';
  ordersStatus = "open";
  allSelected = false;
  action = '';
  countOfCheckedOrders = 0;
  statusTemp = '';
  countSelectOrders = 0;

  showPopUp = false;
  popUpTitle: string;

  addSkuUrls: Array<string> = [];
  errors: Array<any>;
  errorOrderName = '';

  lastSelected: number = -1;

  isPrinterOnline: boolean;

  ngbModalOptions: NgbModalOptions = {
    size: 'lg',
    backdrop : 'static',
    keyboard : false,
    centered: true
  };

  public get isPrintingEnable() {
    if(!this.data.PrintingLayouts){
      return false;
    }
    const selectedLayout = this.data
      .PrintingLayouts
      .find(l => l.id == this.data.DefaultLayoutId);

      if (!selectedLayout) {
        return false;
      }

     const labelSupport = this.data.OnlinePrinter.isNewPrintClient
      || (selectedLayout.format == LayoutFormatType.Zpl && !this.data.OnlinePrinter.isNewPrintClient)

    return !!selectedLayout && labelSupport && this.isPrinterOnline;
  }

  public get isPrintingEnableTitle() {
    if(!this.data.PrintingLayouts){
      return "No labels";
    }
    const selectedLayout = this.data
    .PrintingLayouts
    .find(l => l.id == this.data.DefaultLayoutId);

    if (!selectedLayout) {
      return "Select label";
    }
    
    if (selectedLayout.format == LayoutFormatType.Sld && !this.data.OnlinePrinter.isNewPrintClient) {
      return 'The Sld label format supported only by new printing';
    }

    if (!selectedLayout) {
      return 'Select label';
    }

    if (!this.isPrinterOnline) {
      return 'Selected printer is offline. Select online printer.';
    }
    
    return 'Run to print products';
  }

  @Input() data: RequiredChildsData;

  constructor(private ordersService: OrdersService, private errorModalService: ErrorModalService,
  private urlBuilder :UrlBuilderService, private modalService: NgbModal, 
  private productsService: ProductService, private PrintService: PrintingService) { }

  ngOnInit() {
    this.getOrders(1);
  }

  ngDoCheck() {
    this.countOfCheckedOrders = 0;
    this.orders.forEach(element => {
      if (element.Data.Selected === true) {
        this.countOfCheckedOrders ++;
      }
    });
    if(this.countOfCheckedOrders > 0 && this.searchText){
      this.selectOrder();
    }
    if (this.countOfCheckedOrders === this.orders.length) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }

    this.isPrinterOnline = this.data.OnlinePrinter?.status == PrinterStatus.Online;
  }

  closePopUp() {
    this.showPopUp = false;
  }

  getOrders(page: number) {
    this.loading = true;
    if(this.searchText){
      this.status = null;
    } else {
      this.status = this.status != 'null' ? this.status : 'ready';
    }
    this.ordersService.getOrders(page, this.searchText, this.status, this.data.currentLocationExternalId).subscribe(res => {
      this.orders = res.Entities.filter(e => e.ItemsTotal > 0).map<IOrderViewModel>(_ => {
        return {
          Data: _,
          link: this.urlBuilder.getUrltoOrder(_.Id, this.data, OrderTypes.Sales)
        };
      });
      this.status = res.Status;
      this.salesStatus = 'regular';
      this.pages = res.Pages;
      this.selectedPage = page;
      this.loading = false;
    },
    err => {
      console.log(err);
      this.loading = false;
      this.errorModalService.open('Error', 'loading orders');
    });
  }

  getShopifyRefundOrders(page: number) {
    this.loading = true;
    if(this.searchText){
      this.status = null;
    }
    this.ordersService.getShopifyRefundOrders(page, this.searchText, this.data.currentLocationExternalId).subscribe(res => {
      this.orders = res.Entities.map<IOrderViewModel>(_ => {
        return {
          Data: _,
          link: this.urlBuilder.getUrltoOrder(_.Id, this.data, OrderTypes.Sales)
        };
      });
      this.salesStatus = 'returns';
      this.pages = res.Pages;
      this.selectedPage = page;
      this.loading = false;
    },
    err => {
      console.log(err);
      this.loading = false;
      this.errorModalService.open('Error', 'loading orders');
    });
  }

  getCountQuantity(order){
    var count = 0;order.Items.forEach(item => {
      count += item.Quantity;
    });
    return count;
  }

  searchOrderById(){
    if(this.salesStatus === 'returns'){
      this.getShopifyRefundOrders(1);
    } else {
      this.getOrders(1);
    }
  }

  bulkAction() {
    this[this.action]();
    this.action = '';
    this.allSelected = false;
  };

  selectAll() {
    this.allSelected = !this.allSelected;
    this.orders.forEach(order => {
      order.Data.Selected = this.allSelected;
    });
  }

  selectOrder(){
    for(var order in this.orders){
      if(this.orders[order].Data.Selected){
        if(this.orders[order].Data.AllowSubmit){
          this.status = "ready";
        }
        else{
          this.status = "null";
          break;
        }
      }
    }
  }

  filterStatus() {
    this.searchText = '';
    this.getOrders(1);
  }

  filterSalesStatus(){
    this.searchText = '';
    // if(this.salesStatus === 'regular'){
    //   this.orders = this.salesOrders;
    // } else if(this.salesStatus === 'returns'){
    //   this.orders = this.refundsOrders;
    // }
    if(this.salesStatus === 'regular'){
      this.status = 'ready';
      this.getOrders(1);
    } else if(this.salesStatus === 'returns'){
      this.getShopifyRefundOrders(1);
    }
  }

  private getSelectedOrders(): IOrder[] {
    return this.orders.filter(function(order)
    {
      return order.Data.Selected;
    }).map<IOrder>(o => {
      return o.Data;
    });
  }

  print(){
    this.printOrders(this.getSelectedOrders());
  }

  private printOrders(orders: IOrder[]){
    const orderIds: number[] = orders.map(function (order) { return order.MerchId; });
    this.ordersService.printOrders(orderIds)
      .subscribe(response => {
        const link = document.createElement('a');
        link.target = '_blank';
        link.download = orders.map(function (order) { return order.Name; }).join(',') + '.pdf';
        link.href = window.URL.createObjectURL(response);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });

    orders.forEach(function(order) {
      order.PrintStatus = 'Printed';
      order.Selected = false;
    });
  }

  private printProducts(searchProducts: SearchProductModel[]): Observable<any> {
    return this.PrintService.printProducts(this.data, searchProducts).pipe(
      tap( _=> {
        this.selectedPage = 1;
      }),
      catchError(_ => {
        this.errorModalService.open('Error', 'when printing product');
        this.loading = false;
        return throwError(_);
      })
    );
  }

  submitAndPrint() {
    this.submit(true);
  }

  submit(print: boolean) {
    this.loading = true;
    const orders = this.getSelectedOrders();
    this.ordersService.submitOrdersForQc(orders, this.data.currentLocationExternalId).subscribe(res => {
      if (print) {
        this.printOrders(res);
      }
      this.getOrders(1);
      this.loading = false;
    }, err => {
      this.orders.forEach(o => o.Data.Selected = false);
      this.loading = false;
      this.errorModalService.open('Error', err.message);
    });
  }

  openProductsInfoModal(order: IOrder): void {
    if(order.Status.indexOf("Ready for QC") !== -1){
      if(this.data.isBigCommerce){
        this.ordersService.getItems(order.ProductUrl).subscribe(res => {
          order.Items = res;
        },
        err => {
          console.log(err);
          this.errorModalService.open('Error', 'loading items');
        });
      }
      if(this.data.isShopify){
        let items = order.Items.map<IOrderItem>(_ => {
          return {
            ProductId: _.ProductId,
            Sku: _.Sku,
            VariantId: _.VariantId
          };
        });
        this.ordersService.getShopifyItems(items).subscribe(res => {
          let result = res;
          result.forEach(item => {
            let orderItem = order.Items.find(_ => _.VariantId == item.VariantId);
            item.Quantity = orderItem.Quantity;
            item.Sku = orderItem.Sku;
          });
          order.Items = result;
        },
        err => {
          console.log(err);
          this.errorModalService.open('Error', 'loading items');
        });
      }
    }
    if(order.BaseOrderId){
      this.ordersService.getItemsById(order.BaseOrderId).subscribe(res => {
        order.Items = res;
      },
      err => {
        console.log(err);
        this.errorModalService.open('Error', 'loading items');
      });
    }
    const modalRef = this.modalService.open(ProductPrintModalComponent, this.ngbModalOptions);
    modalRef.componentInstance.order = order;
    modalRef.componentInstance.data = this.data;
  }

  toggleSelected(index, event) {
    if (event.shiftKey) {
      if (this.lastSelected < 0) {
        this.lastSelected = index;
      } else{
        var min = this.lastSelected > index ? Math.min(index, this.lastSelected) + 1: Math.min(index, this.lastSelected);
        var max = Math.max(index, this.lastSelected);
        var checked = this.orders[this.lastSelected].Data.Selected;
        this.orders
          .slice(min, max)
          .forEach(item => item.Data.Selected = checked);
        this.lastSelected = -1;
      }
    } else {
      this.lastSelected = -1;
    }
  }

  copyAndPrint(products: IOrderItemFull[]){
    this.loading = true;
    this.buildCopyProducts(products).pipe(
      switchMap(importedProducts => {
        const importedProductsDic = importedProducts.reduce<{[key: string]: ProductCopyModelResponse}>((acc, cur) => {
          acc[cur.PartnerProductId + cur.PartnerVariandId] = cur;
          return acc;
        }, {});
        
        const productsModel = products.map(p => {
          return [{ ProductId: importedProductsDic['' + p.ProductId + p.VariantId]?.ProductId,
              Quantity: p.Quantity,
              Name: p.Sku,
              Sku: p.Sku }];
        })
        .flat(1)
        .filter(_ => !!_.ProductId && _.Quantity > 0)
        .map<SearchProductModel>(_ => {
          return {
            Name: _.Name,
            ProductId: _.ProductId,
            Quantity: _.Quantity,
            Sku: _.Sku,
          }
        });
        return this.printProducts(productsModel);
      })
    ).subscribe(res =>{
      this.loading = false;
      this.PrintService.goToPrintTab();
    });
  }

  private buildCopyProducts(products: IOrderItemFull[]): Observable<ProductCopyModelResponse[]> {
    const productsToSave = this.buildProductsToSaveList(products);
    return this.productsService.copyProducts(productsToSave);
  }

  buildProductsToSaveList(products: IOrderItemFull[]): ProductCopyModelRequest[] {
    const productsToSave: ProductCopyModelRequest[] = [];
    products.forEach(p => {
        productsToSave.push({
          ProductId: p.ProductId.toString(),
          VariantIds: [p.VariantId]
        });
    });

    return productsToSave.filter(p => p.ProductId);
  }
}
